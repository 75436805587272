@tailwind base;
@tailwind components;
@tailwind utilities;

.video-container {
    position: relative;
    width: 100%;
    height: 400px; /* Fixed height */
    background: #000;
    overflow: hidden;
}

.video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; /* Matches the container height */
    border: none;
}

.gridespecial div {
    padding-top:100%;
}